@font-face {
	font-family: "Metropolis Black";
	font-style: normal;
	font-weight: normal;
	src: local("Metropolis Black"), url("Metropolis-Black.otf") format("opentype");
}

@font-face {
	font-family: "Metropolis Extra Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Metropolis Extra Bold"), url("Metropolis-ExtraBold.otf") format("opentype");
}

@font-face {
	font-family: "Metropolis Medium";
	font-style: normal;
	font-weight: normal;
	src: local("Metropolis Medium"), url("Metropolis-Medium.otf") format("opentype");
}

@font-face {
	font-family: "Metropolis Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Metropolis Bold"), url("Metropolis-Bold.otf") format("opentype");
}
